 <template>
	<div class="page">

		<!-- 搜索条件表单 -->
		<el-form class="ser_form" size="small"  label-width="115px">
			<el-form-item class="el_form_item" label="id">
				<el-input class="el_input" v-model="form.id" placeholder="id搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="流水编号">
				<el-input class="el_input" v-model="form.ra_num" placeholder="流水编号搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="APP用户手机">
				<el-input class="el_input" v-model="form.app_user_tel" type="number" placeholder="用户手机号搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="APP用户身份证">
				<el-input class="el_input" v-model="form.app_user_id_card_num" placeholder="注册人身份证搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="操作人手机">
				<el-input class="el_input" v-model="form.admin_user_tel" type="number" placeholder="操作人手机号搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="公司名称">
				<el-input class="el_input" v-model="form.name" placeholder="公司名称搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="充值类型">
				<el-select class="el_input" v-model="form.balance_type" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="对公余额" value="1"></el-option>
					<el-option label="对私余额" value="2"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="备注">
				<el-input class="el_input" v-model="form.mark" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item elfrom_item" label="充值时间">
				<div class="block">
				<el-date-picker
					v-model="recharge_value"
					type="datetimerange"
					:picker-options="pickerOptions"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					align="right"
					value-format="timestamp"
				>
				</el-date-picker>
				</div>
           </el-form-item>
		   	<el-form-item class="el_form_item">
			</el-form-item>
			<el-form-item label-width="0" style="margin-left: 30px;">
				<el-button type="primary" @click="page_ser">查询</el-button>
				<el-button type="primary" @click="ser_para_clear">清空条件</el-button>
				<el-button type="primary" @click="read">全部导出</el-button>
			</el-form-item>
		</el-form>
		<div class="total_info item">
			<span>共:{{list.total}}条</span>
			<span>总计:{{recharge_total}}元</span>
		</div>
		<!-- 表格 -->
		<div class="tab_height">
			<el-table :data="list.data" :border="true" :stripe="true" size="small" height="100%" v-loading="list.loading">
				<el-table-column label="id" width="205px">
					<template slot-scope="scope">
						<div class="tab_line_item">编号: {{scope.row.id}}</div>
					</template>
				</el-table-column>
				<el-table-column label="流水编号/充值时间" width="205px">
					<template slot-scope="scope">
						<div class="tab_line_item">编号: {{scope.row.ra_num}}</div>
						<div class="tab_line_item">时间: {{scope.row.creat_time_text}}</div>
					</template>
				</el-table-column>
				<el-table-column label="用户信息">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.app_user_info.name}} / {{scope.row.app_user_info.tel}}</div>
						<div class="tab_line_item">{{scope.row.company_info.name}}</div>
					</template>
				</el-table-column>
				<el-table-column label="操作人信息">
					<template slot-scope="scope">
						<div class="tab_line_item">姓名: {{scope.row.admin_user_info.name}}</div>
						<div class="tab_line_item">电话: {{scope.row.admin_user_info.tel}}</div>
					</template>
				</el-table-column>
				<el-table-column label="服务费率">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.shipper_set.extra_service_charge_rate}}</div>
					</template>
				</el-table-column>
				<el-table-column label="充值信息">
					<template slot-scope="scope">
						<div class="tab_line_item">金额: {{scope.row.much}} 元</div>
						<div class="tab_line_item">类型: {{scope.row.balance_type_text}}</div>
					</template>
				</el-table-column>
				<el-table-column label="备注" prop="mark"></el-table-column>
				<el-table-column label="操作" prop="mark">
					<template slot-scope="scope">
					 <el-button  size="mini" type="text" @click="recharge_edit(scope.row)" style="margin-left: 20px">编辑</el-button>
					 </template>
				</el-table-column>
			</el-table>
		</div>

		<!-- 分页 -->
		<el-pagination 
			class="margin-top-xl" 
			@size-change="page_size_change"
			@current-change="page_current_change" 
			:current-page="page.p" 
			:page-sizes="[10, 20, 30, 40]"
			:page-size="page.num" 
			:total="list.total*1"
			layout="total, sizes,prev, pager, next, jumper" 
		>
		</el-pagination>
		<!-- 编辑 -->
		<rechargedit
		:is_show="edit.is_show"
		:data="edit.data"
		@init="get_page_data()"
		></rechargedit>
	</div>
</template>

<script>
import rechargedit from './recharge_edit.vue'
	export default {
		components:{
			rechargedit
		},
		data() {
			return {
				recharge_total:'',//总充值金额
				//搜索条件
				form: {				  
					ra_num:'',//流水编号
					app_user_tel:'',//电话
					app_user_id_card_num:'',//身份证号
					admin_user_tel:'',//充值人员手机
					balance_type:'',//余额类型
					mark:'',//备注
					id:"",//id搜索
					name:'',//公司名称
					creat_time_start:'',//充值开始时间
					creat_time_end:'',//充值结束时间
				},
				buf:'',//导出数据
				excle_list:[],
				recharge_value:[],//充值记录时间筛选
				//表格数据
				list: {
					loading: false,//loading特效
					data: [],//列表数据
					total: 0,//列表总记录数
				},
				//编辑信息
				edit:{
					data:{},
					is_show:0
				},
				pickerOptions: {
					shortcuts: [
					{
						text: "最近一周",
						onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit("pick", [start, end]);
						},
					},
					{
						text: "最近一个月",
						onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit("pick", [start, end]);
						},
					},
					{
						text: "最近三个月",
						onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit("pick", [start, end]);
						},
					},
					],
				},
				//页码信息
				page: {
					need_num:1,
					num: 10,
					p: 1,
				},
				p:1,
			}
		},
		created() {
			
			//读取页面数据
			this.get_page_data()
		},
		methods: {
		   //生成csv
			make_csv(){
				var shunxu=[
					{key:"id",cname:"充值id"},
					{key:"ra_num",cname:"流水编号"},
					{key:"creat_time_text",cname:"充值时间"},
					{key:"app_user_name",cname:"用户姓名"},
					{key:"app_user_tel",cname:"用户手机号"},
					{key:"company_info_name",cname:"公司名称"},
					{key:"admin_user_info_name",cname:"操作人姓名"},
					{key:"admin_user_info_tel",cname:"操作人手机"},
					{key:"extra_service_charge_rate",cname:"服务费率"},
					{key:"much",cname:"充值金额"},
					{key:"balance_type_text",cname:"充值类型"},
					{key:"mark",cname:"备注"},
				],str="";
				for(var a in shunxu){
					str+=shunxu[a].cname+",";
				}
				str+="\n";
				for(var i=0;i<this.buf.length;i++){
					for(var a in shunxu){
						str+=`${this.buf[i][shunxu[a].key]}\t,`;
					}
					str+='\r\n';
				}

				str = "data:text/csv;charset=utf-8,\ufeff" + encodeURIComponent(str);
				
				//生成下载链接
				let link = document.createElement('a');
				link.href = str;

				//对下载的文件命名
				link.download = "充值记录.csv";

				//下载
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			},
		   //读取
			read(){
				var p = this.p
				//读取数据
				this.$my.net.req({
					data:{
						mod:'finance',
						ctr:'recharge_list_admin',
						is_get_app_user_info:1,
						is_get_company_info:1,
						is_get_admin_user_info:1,
						...this.form,
						num:1000,
						p,
					},callback:(data)=>{
						// 导出完毕
						if(data.list.length ==0){
							this.buf = this.excle_list
						    this.make_csv()
							this.p = 1
							return
						}
						//预处理
						for(let item of data.list){
							//下单时间
							item.creat_time_text=this.$my.other.totime(item.creat_time);

							//结算方式
							switch(item.balance_type){
								case '1':item.balance_type_text="对公余额";break;
								case '2':item.balance_type_text="对私余额";break;
							}
							item.app_user_name = item.app_user_info.name
							item.app_user_tel = item.app_user_info.tel
							item.company_info_name = item.company_info.name
							item.admin_user_info_name = item.admin_user_info.name
							item.admin_user_info_tel = item.admin_user_info.tel
							item.extra_service_charge_rate = item.shipper_set.extra_service_charge_rate
							this.excle_list.push(item)
					    }
						this.p ++
						this.read()
                    }
				})
			},
			//清空查询条件
			ser_para_clear(){
				this.form={
					ra_num:'',//流水编号
					app_user_tel:'',//电话
					app_user_id_card_num:'',//身份证号
					admin_user_tel:'',//充值人员手机
					balance_type:'',//余额类型
					mark:'',//备注
					id:'',//id搜索
					name:'',//公司名称
					creat_time_start:'',//充值开始时间
					creat_time_end:'',//充值结束时间
				}
				this.get_page_data()
			},
			// 编辑
			recharge_edit(e){
				this.edit.data = e
				this.edit.is_show++
			},
			//搜索
			page_ser(){
				if(this.recharge_value){
					this.form.creat_time_start = parseInt(this.recharge_value[0] / 1000);
					this.form.creat_time_end = parseInt(this.recharge_value[1] / 1000);
				}else{
                    this.form.creat_time_start='',
					this.form.creat_time_end=''
				}
				//读取第一页
				this.page.p=1

				//读取
				this.get_page_data()
			},

			//页宽变化
			page_size_change(num){

				//置入页码
				this.page.num=num

				//读取
				this.get_page_data()
			},

			//页码变化
			page_current_change(p){
				
				//置入页码
				this.page.p=p

				//读取
				this.get_page_data()
			},

			//获取货源列表
			get_page_data(){

				//加载中...
				if(this.list.loading){
					return;
				}

				//置为加载中
				this.list.loading=true;

				//读取数据
				this.$my.net.req({
					data:{
						mod:'finance',
						ctr:'recharge_list_admin',
						is_get_app_user_info:1,
						is_get_company_info:1,
						is_get_admin_user_info:1,
						...this.form,
						...this.page
					},
					callback:(data)=>{

						//加载完毕
						this.list.loading=false

						//总数
						this.list.total=data.max

						this.recharge_total = data.recharge_total
						//预处理
						for(let item of data.list){

							//下单时间
							item.creat_time_text=this.$my.other.totime(item.creat_time);

							//结算方式
							switch(item.balance_type){
								case '1':item.balance_type_text="对公余额";break;
								case '2':item.balance_type_text="对私余额";break;
							}
						}

						//渲染
						this.list.data=data.list
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.page{
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		height: calc(100% - 40px);
		overflow: auto;
	}
	.ser_form{
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}
	.tab_height{
		height:calc(100% - 134px);
	}
	.el_form_item{
		width: 19%;
	}
	.el_input{
		width:94%
	}
	.btn_left{
		margin-left: 0;
	}
	.total_info {
		text-align: right;
		font-size: 12px;
		margin-bottom: 24px;
		margin-right: 1px;
		margin-top: 10px;
		color: #606266;
		
		span {
			margin-left: 20px;
		}
	}
</style>
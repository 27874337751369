<template>
  <el-dialog top="1vh" title="编辑" width="1050px" :visible.sync="is_show_in_page">
    <el-form class="form_ordinary" label-width="165px">
      <div class="big_tit" style="margin-top: 0px">货主充值信息</div>
      <div class="tab1_inner">
        <el-form-item class="el_form_item" label="付款方银行" style="width: 450px;margin: 10px 0px">
          <el-input class="el_inner_width" v-model="form.payment_bank_name"></el-input>
        </el-form-item>
        <el-form-item class="el_form_item" label="付款方银行号码" style="width:450px; margin: 10px 0px" >
          <el-input class="el_inner_width" v-model="form.payment_account" ></el-input>
        </el-form-item>
        <el-form-item class="el_form_item" label="收款方银行" style="width:450px; margin: 10px 0px" >
        <el-select class="el_input" v-model="form.receipt_bank_name" clearable style="width:268px" @change="bank()">
          <el-option label="徽商银行安庆港口支行" value="徽商银行安庆港口支行"></el-option>
          <el-option label="安庆农村商业银行城东支行" value="安庆农村商业银行城东支行"></el-option>
          <el-option label="徽商银行安庆中兴路支行" value="徽商银行安庆中兴路支行"></el-option>
        </el-select>
      </el-form-item>
        <!-- <el-form-item class="el_form_item" label="收款方银行" style="width:450px; margin: 10px 0px">
          <el-input class="el_inner_width" v-model="form.receipt_bank_name" ></el-input>
        </el-form-item> -->
      <el-form-item class="el_form_item" label="收款方银行号码" style="width:450px; margin: 10px 0px" >
        <el-select class="el_input" v-model="form.receipt_account" clearable style="width:268px" @change="card()">
          <el-option label="225004083141000004" value="225004083141000004"></el-option>
          <el-option label="20010213563966600000018" value="20010213563966600000018"></el-option>
          <el-option label="225004083101000006" value="225004083101000006"></el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item class="el_form_item" label="收款方银行号码" style="width: 450px; margin: 10px 0px" >
          <el-input class="el_inner_width" v-model="form.receipt_account" ></el-input>
       </el-form-item> -->
        <el-form-item class="el_form_item" label="充值流水号" style="width: 450px; margin: 10px 0px">
          <el-input class="el_inner_width" v-model="form.sequence_code" ></el-input>
        </el-form-item>
      </div>
         <div class="big_tit" style="margin-top: 0px" v-if="balance_type==4">司机汇款信息</div>
         <div class="tab1_inner">
        <el-form-item class="el_form_item" label="支付给代收人的银行" v-if="balance_type==4" style="width:450px; margin: 10px 0px" >
          <el-input class="el_inner_width" v-model="form.replace_pay_bank_name" ></el-input>
        </el-form-item>
        <el-form-item class="el_form_item" label="支付给代收人银行卡号" v-if="balance_type==4" style="width:450px; margin: 10px 0px">
          <el-input class="el_inner_width" v-model="form.replace_pay_account" ></el-input>
        </el-form-item>
        <el-form-item class="el_form_item" label="支付给代收人流水号" v-if="balance_type==4" style="width:450px; margin: 10px 0px">
          <el-input class="el_inner_width" v-model="form.replace_code" ></el-input>
        </el-form-item>
        <el-form-item class="el_form_item" label="代收人银行" v-if="balance_type==4" style="width: 450px; margin: 10px 0px" >
        <el-input class="el_inner_width" v-model="form.replace_bank_name"></el-input>
        </el-form-item>
         <el-form-item class="el_form_item" label="代收人银行卡" v-if="balance_type==4" style="width: 450px; margin: 10px 0px" >
           <el-input class="el_inner_width" v-model="form.replace_account"></el-input>
        </el-form-item>
        <el-form-item class="el_form_item" label="油费支付的运单id号列表" v-if="balance_type==4" style="width: 450px; margin: 10px 0px">
        <el-input
           type="textarea"
           style="width:268px"
           placeholder="请输入id列表"
           v-model="form.act_fuel_list">
        </el-input>
         </el-form-item>
      </div>
        <el-form-item class="el_form_item" label="上报信息"  label-width="180px" >
          <el-input class="el_inner_width" v-model="form.up_status" style="width: 268px"></el-input>
        </el-form-item>
        <div class="btn_area">
			<el-button type="primary" @click="recharge_edit_set">保存</el-button>
		</div>
    </el-form>
  </el-dialog>
</template>
<script>
import { Loading } from "element-ui";
export default {
  props: {
    is_show: Number, //是否显示
    data:Object
  },
  data() {
    return {
      //页面是否显示
      is_show_in_page: false,
      balance_type:'',
      id:'',
      form:{
        payment_bank_name:'', //付款方银行（货主）
        payment_account:'', // 付款方银行号码
        receipt_bank_name:'', // 收款方银行（骏功）
        receipt_account:'', // 收款方银行号码
        sequence_code:'', // 流水号
        replace_pay_bank_name:'', // 支付给代收人的银行（balance_type=4生效）
        replace_pay_account:'', // 支付给代收人的银行卡号
        replace_bank_name:'', // 代收人银行（第三方油费）
        replace_account:'', // 代收人银行卡
        replace_code:'', // 支付给代收人流水号
        act_list:'',// 用于油费支付的运单号列表
        act_fuel_list:'', // 用于油费支付的运单号列表
        up_status :'', //0未填写，1已填写上报信息
      }
    };
  },
  watch: {
    is_show(new_data) {
      if (new_data) {
        this.is_show_in_page = true;
      }
    },
    data(new_data) {
      if(new_data){
        this.balance_type = new_data.balance_type
        this.id = new_data.id
        this.form ={
            payment_bank_name:new_data.payment_bank_name, //付款方银行（货主）
            payment_account:new_data.payment_account, // 付款方银行号码
            receipt_bank_name:new_data.receipt_bank_name, // 收款方银行（骏功）
            receipt_account:new_data.receipt_account, // 收款方银行号码
            sequence_code:new_data.sequence_code, // 流水号
            replace_pay_bank_name:new_data.replace_pay_bank_name, // 支付给代收人的银行（balance_type=4生效）
            replace_pay_account:new_data.replace_pay_account, // 支付给代收人的银行卡号
            replace_bank_name:new_data.replace_bank_name, // 代收人银行（第三方油费）
            replace_account:new_data.replace_account, // 代收人银行卡
            replace_code:new_data.replace_code, // 支付给代收人流水号
            act_list:new_data.act_list,
            act_fuel_list:new_data.act_fuel_list, // 用于油费支付的运单号列表
        }
        if(new_data.up_status ==0){
            this.form.up_status ="未填写"
        }else{
            this.form.up_status ="已填写上报信息"
        }
      }
    },
  },
  methods: {
      bank(){
        if(this.form.receipt_bank_name =="徽商银行安庆港口支行"){
          this.form.receipt_account = "225004083141000004"
        }else if(this.form.receipt_bank_name =="安庆农村商业银行城东支行"){
          this.form.receipt_account = "20010213563966600000018"
        }else if(this.form.receipt_bank_name =="徽商银行安庆中兴路支行"){
          this.form.receipt_account ="225004083101000006"
        }
      },
      card(){
        if(this.form.receipt_account =="225004083141000004"){
            this.form.receipt_bank_name = "徽商银行安庆港口支行"
          }else if(this.form.receipt_account =="20010213563966600000018"){
            this.form.receipt_bank_name = "安庆农村商业银行城东支行"
          }else if(this.form.receipt_account =="225004083101000006"){
            this.form.receipt_bank_name ="徽商银行安庆中兴路支行"
          }
      },
      //保存
      recharge_edit_set(){
          this.$my.net.req({
              data:{
                  mod:'finance',
                  ctr:'edit_recharge',
                  id:this.id,
                  balance_type:this.balance_type,
                  ...this.form
              },
              callback:(data)=>{
              this.$my.other.msg({
                 type:'success',
                 str:"保存成功"
                });
                this.is_show_in_page = false
                this.$emit('init')
              }
          })
      }
    },
};
</script>
<style lang="scss" scoped>
.el_form_item {
  margin: 0;
  .right{
    margin-right: 20px;
  }
  .bottom_text {
    margin-top: 5px;
    margin-bottom: 20px;
    text-align: center;
  }
}
.big_tit {
  background-color: #eee;
  padding: 10px 20px;
  color: #666;
  font-weight: 600;
  font-size: 18px;
  margin-top: 20px;
}
.tab1_inner {
  padding: 15px 0px 0 15px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
.el_inner_width {
  width: 94%;
}

.bottom_btns {
  text-align: center;
  margin-top: 30px;
}

.form_ordinary {
  // height: calc(100% - 91px);
  padding: 10px 0;
  overflow-y: auto;
}
  .btn_area {
    text-align: center;
    margin-top: 15px;
  }
</style>